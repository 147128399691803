import { Button, Form, Input, Select, Popconfirm } from "antd";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../store";
import { createReport, deleteReport, fetchReports, Report, updateReport } from "../store/reportsSlice";
import { breakpoint } from "../theme";
import DebounceSelect from "./DebounceSelect";

const StyledModal = styled(Modal)`
  width: 100% !important;
  @media${breakpoint.device.sm} {
    width: 650px !important;
  }
`;
interface Props {
  report?: Optional<Report, "id">;
  onClose: () => void;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const searchAdGroup = (group) => {
  return axios.get(`/ad-groups?filter=${group}`).then((res) => res.data.map((e) => ({ label: e.displayName, value: e.displayName })));
};

const searchPowerbiGroup = (group) => {
  return axios.get(`/powerbi-groups?filter=${group}`).then((res) => res.data.map((e) => ({ label: `${e.name} - ${e.id}`, value: e.id })));
};

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
function CreateReportForm({ report, onClose }: Props) {
  const dispatch = useAppDispatch();
  const unitGroups = useAppSelector((state) => state.reports.unitGroups);
  const unitGroupsLoading = useAppSelector((state) => state.reports.unitGroupsLoading);
  const loading = useAppSelector((state) => state.reports.reportLoading);
  const mounted = useRef(false);
  const formRef = useRef(null);
  const [powerbiReports, setPowerbiReports] = React.useState([]);
  //   t is a form, where you should input a name, a powerbi id and select a group from a dropdown
  // name and power bi  id are text inputs

  useEffect(() => {
    if(formRef && !mounted.current) {
      //@ts-ignore
      formRef.current?.resetFields();
      mounted.current = true;
    }
    if(report && report.powerbiGroupId){
      searchPowerbiReports(report.powerbiGroupId);
    }
    return formRef.current?.resetFields();
  },[formRef, report])



  const refetchReports = () => dispatch(fetchReports());

  const onFinish = (data) => {

    const payload = {
      ...report,
      ...data,
    };
    if (typeof payload.groupName === "object") {
      payload.groupName = payload.groupName.value;
    }
    if (typeof payload.powerbiGroupId === "object") {
      payload.powerbiGroupId = payload.powerbiGroupId.value;
    }
    if (report.id) {
      return dispatch(updateReport(payload)).then((_) => {
        refetchReports();
        onClose();
      });
    }
    return dispatch(createReport(payload)).then((_) => {
      refetchReports();
      onClose();
    });
  };

  const onDelete = () => {
    const id = report.id;
    if (id) {
      dispatch(deleteReport(id)).then((_) => {
        refetchReports();
        onClose();
      });
    }
  };

  const searchPowerbiReports = (groupId) => {
    return axios.get(`/powerbi-groups/${groupId}/reports`).then((res) => setPowerbiReports(res.data));
  };

  const onPowerbiGroupChange = group => {
    searchPowerbiReports(group.value);
    return group;
  }

  return (
    <StyledModal visible={!!report} footer={null} onCancel={() => onClose()} >
      <Form
        ref={formRef}
        style={{ margin: 20 }}
        {...layout}
        name="basic"
        initialValues={{
          groupName: {label: report?.groupName, value: report?.groupName},
          businessUnitGroup: report?.businessUnitGroup,
          powerbiSrName: report?.powerbiSrName,
          displayName: report?.displayName,
          powerbiId: report?.powerbiId,
          powerbiGroupId: {label: report?.powerbiGroupId, value: report?.powerbiGroupId},
        }}
        onFinish={onFinish}
      >
        <Form.Item label="Group Name" name="groupName" rules={[{ required: true }]}>
          <DebounceSelect showSearch onSearch={searchAdGroup} />
        </Form.Item>

        <Form.Item label="Unit Group" name="businessUnitGroup" rules={[{ required: true }]}>
          <Select loading={unitGroupsLoading} options={unitGroups.map((e) => ({ label: e, value: e.toString() }))}></Select>
        </Form.Item>
        <Form.Item label="Display Name" name="displayName" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Powerbi Group Id" name="powerbiGroupId" rules={[{ required: true }]}>
          <DebounceSelect showSearch onSearch={searchPowerbiGroup} onSelect={onPowerbiGroupChange}/>
        </Form.Item>
        <Form.Item label="Powerbi Id" name="powerbiId" rules={[{ required: true }]}>
        <Select options={powerbiReports.map((e) => ({ label: e.id, value: e.id.toString() }))}></Select>
        </Form.Item>
        <Form.Item label="Powerbi Sr Name" name="powerbiSrName">
          <Input />
        </Form.Item>
        

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
          {report?.id && (
            <Popconfirm
              title="Are you sure to delete this report?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ marginLeft: 10 }} loading={loading} type="dashed" htmlType="reset">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Form.Item>
      </Form>
    </StyledModal>
  );
}

export default CreateReportForm;
