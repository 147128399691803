import { CheckCircleTwoTone, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import styled, { keyframes } from "styled-components";
import { useAppDispatch, useAppSelector } from "../store";
import { MessageType, removeMessage } from "../store/messagesSlice";

interface Props {}

const slide = keyframes`

  0% { transform: translate(100%, 0); }
  5% { transform: translate(0, 0);}
  95% { transform: translate(0, 0);  }
  100% { transform: translate(120%, 0);  }
`;

const getColorByType = (type: MessageType) => {
  switch (type) {
    case "success":
      return "#2ecc71";
    case "info":
      return "#3498db";
    case "warning":
      return "#e67e22";
    case "error":
      return "#e74c3c";
  }
  return "white";
};
const StyledMessage = styled.div`
  width: 350px;
  background-color: ${(props) => getColorByType(props.type)};
  font-size: 18px;
  border-radius: 10px;
  padding: 10px;
  color: white;

  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  margin: 10px;
  animation: ${slide} ${(props: { duration: number; type: MessageType }) => props.duration}s ease-out;
  animation-fill-mode: forwards;
`;

const StyledContainer = styled.div`
  position: fixed;
  right: 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 50px;
  z-index: 99 !important;
`;

function Messages(props: Props) {
  const messages = useAppSelector((state) => state.messages);
  const dispatch = useAppDispatch();

  const iconStyle = {
    fontSize: 50,
  };
  const getIcon = (type: MessageType) => {
    switch (type) {
      case "success":
        return <CheckCircleTwoTone style={iconStyle} twoToneColor="white" />;

      case "warning":
      case "info":
        return <ExclamationCircleOutlined style={iconStyle} twoToneColor="white" />;
      case "error":
        return <CloseCircleOutlined style={iconStyle} twoToneColor="white" />;
    }
  };
  return (
    <StyledContainer>
      {messages.map((el, index) => (
        <StyledMessage type={el.type} duration={el.timeout} key={el.id} onClick={() => dispatch(removeMessage(el.id))}>
          <div style={{ position: "absolute" }}>{getIcon(el.type)}</div>
          <p style={{ textAlign: "center", fontWeight: "bold", marginTop: 10 }}>{el?.message} </p>
          <p style={{ fontSize: 15 }}>{el.description}</p>
        </StyledMessage>
      ))}
    </StyledContainer>
  );
}
export default Messages;
