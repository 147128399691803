import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

export interface User {
  isAdministrator: boolean;
  lastReportGetTime?: string;
  reportIds?: number[];
  userName: string;
}
export interface AdUser {
  id: string;
  userPrincipalName: string;
}

export const createUser = createAsyncThunk("report/update", async (user: User) => {
  return axios.post(`/users`, user).then((res) => res.data);
});
export const updateUser = createAsyncThunk("report/update", async (user: User) => {
  return axios.put(`/users/${encodeURIComponent(user.userName)}`, user).then((res) => res.data);
});
export const fetchUsers = createAsyncThunk("users/fetch", async (_, { getState }) => {
  return axios.get(`/users`).then((res) => res.data);
});

export const fetchAdUsers = createAsyncThunk("adUsers/fetch", async (_, { getState }) => {
  //@ts-ignore
  if (getState().users.adUsers?.length) {
    return Promise.reject();
  }
  return axios.get(`/ad-users`).then((res) => res.data);
});
export const fetchUser = createAsyncThunk("user/fetch", async (userName: string) => {
  return axios.get(`/users/${encodeURIComponent(userName)}`).then((res) => res.data);
});
export const deleteUser = createAsyncThunk("user/update", async (userName: string) => {
  return axios.delete(`/users/${encodeURIComponent(userName)}`).then((res) => res.data);
});

export const fetchCurrentUser = createAsyncThunk("currentUser/fetch", async (userName: string, { getState }) => {
  return axios.get(`/users/${encodeURIComponent(userName)}`).then((res) => res.data);
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: {} as User,
    loadingAdUsers: false,
    loadingUser: false,
    loading: false,
    loadingCurrentUser: true,
    data: [] as User[],
    adUsers: [] as AdUser[],
    impersonateUser: "",
    username: ""
  },
  reducers: {
    setUser(state, action: PayloadAction<string>) {
      state.username = action.payload;
    },
  },
  extraReducers: {
    [fetchUsers.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [fetchUsers.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchUsers.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [updateUser.fulfilled.type]: (state, action) => {
      state.loadingUser = false;
    },
    [updateUser.pending.type]: (state, action) => {
      state.loadingUser = true;
    },
    [updateUser.rejected.type]: (state, action) => {
      state.loadingUser = false;
    },
    [fetchUser.fulfilled.type]: (state, action) => {
      state.loadingUser = false;
    },
    [fetchUser.pending.type]: (state, action) => {
      state.loadingUser = true;
    },
    [fetchUser.rejected.type]: (state, action) => {
      state.loadingUser = false;
    },
    [fetchCurrentUser.fulfilled.type]: (state, action) => {
      state.loadingCurrentUser = false;
      state.currentUser = action.payload;
    },
    [fetchCurrentUser.pending.type]: (state, action) => {
      state.loadingCurrentUser = true;
    },
    [fetchCurrentUser.rejected.type]: (state, action) => {
      state.loadingCurrentUser = false;
    },
    [fetchAdUsers.fulfilled.type]: (state, action) => {
      state.loadingAdUsers = false;
      state.adUsers = action.payload;
    },
    [fetchAdUsers.pending.type]: (state, action) => {
      state.loadingAdUsers = true;
    },
    [fetchAdUsers.rejected.type]: (state, action) => {
      state.loadingAdUsers = false;
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;

export interface CurrentAccount {
  userName: string;
  name: string;
  jwtAccessToken: string;
  jwtIdToken: string;
}

export interface AccountResponse {
  account: Account;
  jwtIdToken: string;
  jwtAccessToken: string;
}

export interface Account {
  accountIdentifier: string;
  homeAccountIdentifier: string;
  userName: string;
  name: string;
  idToken: IDToken;
  idTokenClaims: IDToken;
  environment: string;
}

export interface IDToken {
  aud: string;
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  aio: string;
  idp: string;
  name: string;
  nonce: string;
  oid: string;
  preferred_username: string;
  rh: string;
  sub: string;
  tid: string;
  uti: string;
  ver: string;
}
