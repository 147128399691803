import axios from "axios";
import { useAppDispatch } from "./store";
import { pushMessage } from "./store/messagesSlice";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

function useInterceptors({ envConfig, token }) {
  const dispatch = useAppDispatch();
  const [ready, setReady] = useState(false);
  const impersonate =  localStorage.getItem('ActAs') ? JSON.parse(localStorage.getItem('ActAs')) : ""; 
  const { instance } = useMsal();
  
  useEffect(() => {
    if(!envConfig || !token) return;
    setReady(true);

    const requestInterceptor = axios.interceptors.request.use(
      function (config) {

        config.baseURL = envConfig.API_URL;
        config.headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        if(impersonate && impersonate !== ""){
          config.headers = { ...config.headers, ...{ "ActAs": impersonate }}
        }
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      // Add a response interceptor
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        if (!error.response.status) return Promise.reject(error);
        console.log(error.response.status);
        const resData = error.response?.data;
        if (error.response.status === 404 && resData === "Cannot get specific user") {
          return Promise.reject(error);
        }

        dispatch(
          pushMessage({
            message: "Error " + error?.response?.status,
            description: error.message || "something went wrong",
            type: "error",
          })
        );
        if (error.response.status === 401) {
          //localStorage.clear();
          localStorage.removeItem('ActAs');
          const request = {
            scopes: [envConfig.auth.scope],
          };
          instance.acquireTokenRedirect(request).then();
          console.log("logout should called");
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.request.eject(responseInterceptor);
    };
  }, [dispatch, envConfig, token, instance, impersonate]);

  return ready;
}

export default useInterceptors;
