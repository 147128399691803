import { Button, Checkbox, Input, List } from "antd";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import BlankCard from "../components/BlankCard";
import { Loading } from "../components/Loading";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchUsers } from "../store/usersSlice";
import CreateUserForm from "../components/CreateUserForm";
import { Redirect } from "react-router";
import { breakpoint } from "../theme";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  @media${breakpoint.device.sm}{
    flex-direction: row;
  }
`;

const Filter = styled.div`
  width: 100%;
  @media${breakpoint.device.sm}{
    width: 50%;
  }
`;



function Users() {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.users.loading);
  const users = useAppSelector((state) => state.users.data);
  const isAdmin = useAppSelector((state) => state.users.currentUser.isAdministrator);
  const [currentUser, setCurrentUser] = useState("");
  const [filterUsername, setFilterUsername] = useState("");
  const [filterAdminsOnly, setFilterAdminsOnly] = useState(false);
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const filteredUsers = useMemo(() => {
    return users.filter((el) => el.userName.includes(filterUsername) && (filterAdminsOnly ? el.isAdministrator === filterAdminsOnly : true));
  }, [users, filterUsername, filterAdminsOnly]);

  if (loading) return <Loading />;
  if (!isAdmin) return <Redirect to="/" />;

  return (
    <BlankCard>
      <Row>
        <CreateUserForm onClose={() => setCurrentUser(null)} userName={currentUser} />
        <Filter>
          <Input.Search placeholder="Search by Username" value={filterUsername} onChange={(e) => setFilterUsername(e.target.value)} />
          <Checkbox style={{ marginTop: 10 }} onChange={() => setFilterAdminsOnly(!filterAdminsOnly)} checked={filterAdminsOnly}>
            Show Admins Only
          </Checkbox>
        </Filter>
      </Row>
      <List
        dataSource={filteredUsers}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button onClick={() => setCurrentUser(item.userName)} key={item.userName}>
                edit
              </Button>,
            ]}
          >
            <List.Item.Meta style={{wordBreak: "break-word"}} title={<p>{item.userName}</p>} description={item.isAdministrator && "Admin"} />
          </List.Item>
        )}
      />
    </BlankCard>
  );
}

export default Users;
