import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { groupBy } from "lodash";

export const fetchReports = createAsyncThunk("reports/fetch", async (_, { getState }) => {
  return axios.get(`/reports`).then((res) => res.data);
});
export const fetchReportsIfEmpty = createAsyncThunk("reports/fetch", async (_, { getState }) => {
  //@ts-ignore
  if (getState().reports.data?.length) {
    return Promise.reject();
  }
  return axios.get(`/reports`).then((res) => res.data);
});

export const fetchReport = createAsyncThunk("report/fetch", async (id: string) => {
  return axios.get(`/reports/${id}`).then((res) => res.data);
});

export const fetchUnitGroupsIfEmpty = createAsyncThunk("bussinessUnitGroups/fetch", async (_, { getState }) => {
  //@ts-ignore
  if (getState().reports.data?.length) {
    return Promise.reject();
  }
  return axios.get(`/business-unit-groups`).then((res) => res.data);
});

export const fetchReportEmbedToken = createAsyncThunk(`reports/generateViewToken`, async (id: string) => {
  return axios.get(`/reports/${id}/view-embeded-token`).then((res) => res.data);
});

export const createReport = createAsyncThunk("report/create", async (report: Omit<Report, "id">) => {
  return axios.post(`/reports`, report).then((res) => res.data);
});
export const updateReport = createAsyncThunk("report/create", async (report: Report) => {
  return axios.put(`/reports/${report.id}`, report).then((res) => res.data);
});
export const deleteReport = createAsyncThunk("report/create", async (id: number) => {
  return axios.delete(`/reports/${id}`).then((res) => res.data);
});

// Then, handle actions in your reducers:
const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    reportLoading: false,
    unitGroupsLoading: false,
    loading: false,
    groupedReports: {} as GroupedReports,
    data: [] as Report[],
    reportName: "",
    embedTokenLoading: false,
    embedToken: {} as EmbedTokenResponse,
    unitGroups: [] as String[],
    noReports: false
  },
  reducers: {
    setReportName(state, action: PayloadAction<string>) {
      state.reportName = action.payload;
    },
  },
  extraReducers: {
    [fetchReports.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.groupedReports = groupBy(action.payload, "businessUnitGroup");
      if(Object.keys(state.groupedReports).length === 0){
        state.noReports = true;
      } else {
        state.noReports = false;
      }
    },
    [fetchReports.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchReports.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [fetchReport.fulfilled.type]: (state, action) => {
      state.reportName = action.payload.displayName;
    },
    [fetchReportEmbedToken.fulfilled.type]: (state, action) => {
      state.embedTokenLoading = false;
      state.embedToken = action.payload;
    },
    [fetchReportEmbedToken.pending.type]: (state, action) => {
      state.embedTokenLoading = true;
    },
    [fetchReportEmbedToken.rejected.type]: (state, action) => {
      state.embedTokenLoading = false;
    },
    // [createReport.fulfilled.type]: (state, action) => (state.reportLoading = false),
    // [createReport.pending.type]: (state, action) => (state.reportLoading = true),
    // [createReport.rejected.type]: (state, action) => (state.reportLoading = false),

    [updateReport.fulfilled.type]: (state, action) => {
      state.reportLoading = false;
    },
    [updateReport.pending.type]: (state, action) => {
      state.reportLoading = true;
    },
    [updateReport.rejected.type]: (state, action) => {
      state.reportLoading = false;
    },

    [fetchUnitGroupsIfEmpty.fulfilled.type]: (state, action) => {
      state.unitGroupsLoading = false;
      state.unitGroups = action.payload;
    },
    [fetchUnitGroupsIfEmpty.pending.type]: (state, action) => {
      state.unitGroupsLoading = true;
    },
    [fetchUnitGroupsIfEmpty.rejected.type]: (state, action) => {
      state.unitGroupsLoading = false;
    },

    // [deleteReport.fulfilled.type]: (state, action) => (state.reportLoading = false),
    // [deleteReport.pending.type]: (state, action) => (state.reportLoading = true),
    // [deleteReport.rejected.type]: (state, action) => (state.reportLoading = false),
  },
});

export const { setReportName } = reportsSlice.actions;
export default reportsSlice.reducer;

interface GroupedReports {
  [key: string]: Report[];
}
export interface Report {
  powerbiGroupId: string;
  powerbiId: string;
  businessUnitGroup: string;
  displayName: string;
  groupName: string;
  id: number;
  powerbiSrName: string | null;
}

export interface EmbedTokenResponse {
  id: string;
  embedUrl: string;
  embedToken: EmbedToken;
}

interface EmbedToken {
  token: string;
  tokenId: string;
  expiration: Date;
}
